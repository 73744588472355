import logo from "./logo.svg";
import "./App.css";
import Login from "./components/login/Login";
import Homepage from "./components/homepage/Homepage";
import NavBar from "./components/navBar/NavBar";
import Failed from "./components/failed/Failed";
import UsersList from "./components/userList/UsersList";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  BrowserRouter,
} from "react-router-dom";
import RecruitList from "./components/recruitList/RecruitList";
import AddRecruit from "./components/addRecruit/AddRecruit";
import RecruitProfile from "./components/recruitProfile/RecruitProfile";
import Teams from "./components/teams/Teams";
import TrackingList from "./components/trackingList/TrackingList";
import Phases from "./components/phases/Phases";
import { myContext } from "./Context";
import React, { useContext, useState, useEffect, createContext } from "react";
import Context from "./Context";
import axios from "axios";
import DecisionsList from "./components/DecisionsList/DecisionsList";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0247A1",
    },
    secondary: {
      main: "#FFFFFF",
    },
    accent: {
      main: "#0262DE",
    },
  },
});

function App(props) {
  const userObject = useContext(myContext);

  return (
    <MuiThemeProvider theme={theme}>
      <BrowserRouter>
        <div>
          {userObject.isLoggedIn ? <NavBar /> : null}
          <Switch>
            <Context>
              <Route path="/homepage" exact component={Homepage} />
              <Route path="/" exact component={Login} />
              <Route path="/failed" exact component={Failed} />
              <Route path="/users" exact component={UsersList} />
              <Route path="/feedback" exact component={RecruitList} />
              <Route path="/formrecruit/:id" component={AddRecruit} />
              <Route path="/profile/:id" exact component={RecruitProfile} />
              <Route path="/teams" exact component={Teams} />
              <Route path="/tracking" exact component={TrackingList} />
              <Route path={"/decisions"} exact component={DecisionsList} />
              <Route path={"/phases"} exact component={Phases} />
            </Context>
          </Switch>
        </div>
      </BrowserRouter>
    </MuiThemeProvider>
  );
}

export default App;
