import "./RecruitProfile.css";
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import DoneIcon from "@material-ui/icons/Done";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import AddFeedback from "../addFeedback/AddFeedback";
import Rating from "@material-ui/lab/Rating";
import {useTracking} from "react-tracking";
import {BACKEND_URL} from "../../config";


const convertDetailName = (detailName) => {
  let detailNameConverted;
  switch (detailName) {
    case "leadership":
      detailNameConverted = "Leadership";
      break;
    case "offerFeedback":
      detailNameConverted = "Give feedback";
      break;
    case "receiveFeedback":
      detailNameConverted = "Receive feedback";
      break;
    case "social":
      detailNameConverted = "Sociable";
      break;
    case "friendly":
      detailNameConverted = "Friendly";
      break;
    default:
      detailNameConverted = "Other";
  }
  return detailNameConverted;
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
  },
  addButton: {
    backgroundColor: "#28e328",
    marginLeft: "10px",
    marginRight: "5px",
    color: "white",
    borderRadius: "20%",
    padding: "7px",
    "&:hover": {
      backgroundColor: "#28e328",
      color: "whitesmoke",
    },
  },
  deleteButton: {
    backgroundColor: "#f31414",
    marginRight: "5px",
    color: "white",
    borderRadius: "20%",
    padding: "7px",
    "&:hover": {
      backgroundColor: "#f31414",
      color: "whitesmoke",
    },
  },
  maybeButton: {
    backgroundColor: "#e2c614",
    color: "white",
    borderRadius: "20%",
    padding: "7px",
    "&:hover": {
      backgroundColor: "#e2c614",
      color: "whitesmoke",
    },
  },
}));

const phases = {
  interviewPhase: "interviewPhase",
  acFirstPhase: "acFirstPhase",
  acSecondPhase: "acSecondPhase",
  teamPhase: "teamPhase",
  mentions: "mentions"
};

function RecruitProfile(props) {
  const classes = useStyles();
  let { id } = useParams();

  const { trackEvent } = useTracking({ page: "RecruitProfile" });


  const [recruitData, setRecruitData] = useState([]);
  const [feedbackData, setFeedbackData] = useState([]);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [userData, setUserData]=useState({});
  const [fileId, setFileId] = useState("");
  const[decisionOne,setDecisionOne]=useState("");
  const[decisionTwo,setDecisionTwo]=useState("");
  const [finalOptionsChecked, setFinalOptionsChecked] = useState(false);

  useEffect(() => {
    axios.get(BACKEND_URL +'/api/getuser',{withCredentials:true})
        .then((res)=> {
          setUserData(res.data);
        })
        .catch((err) => {
          console.log('Error:',err);
        })
    axios.get(`${BACKEND_URL}/api/recruit/withDecision/${id}`,{withCredentials:true})
        .then(res=> {
          switch (res.data.decisionOne) {
            case "ACCEPTAT":
              setDecisionOne("ACCEPTAT")
              break;
            case "REFUZAT":
              setDecisionOne("REFUZAT");
              break;
            case "INDECIS":
              setDecisionOne("INDECIS");
              break;
          }
          switch (res.data.decisionTwo) {
            case "ACCEPTAT":
              setDecisionTwo("ACCEPTAT")
              break;
            case "REFUZAT":
              setDecisionTwo("REFUZAT");
              break;
            case "INDECIS":
              setDecisionTwo("INDECIS");
              break;
          }
        })
        .catch(err => {
          console.log('Error:',err);
        })

  },[])

  useEffect(async () => {
    try {
      await axios
        .get(BACKEND_URL +"/api/recruit/" + id, { withCredentials: true })
        .then((res) => {
          setRecruitData(res.data);
            axios
                .get(`https://recrutari-app.sisc.ro/api/recruits/getOneRecruit/${res.data.token}`)
                .then((resI) => {
                    setFinalOptionsChecked(resI.data.optionChecked);
                })
                .catch((err) => {})
           axios.get(BACKEND_URL +"/api/recruit/drive?token=" + res.data.token , { withCredentials: true })
          .then(res => {
              setFileId(res.data);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }

    try {
      await axios
        .get(BACKEND_URL +"/api/feedback/all/" + id, { withCredentials: true })
        .then((res) => {
          setFeedbackData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  }, [feedbackModal]);

  const handleDecision = (recruitId,optionNo,decision) => {
    axios.post(BACKEND_URL +"/api/recruit/castDecision",{recruitId,optionNo,decision})
        .then(res=>{
          if (optionNo===1) {
            setDecisionOne(decision)
          }
          else {
            setDecisionTwo(decision);
          }
        })
        .catch(err => {
          console.log(err);
        })
  }

  const trackDecisionChange = (id,from,to,decision) => {
    trackEvent({
      event:`CHANGE_DECISION_${decision}`,
      target:id,
      from:from,
      to:to
    })

  }


  const handleModalClose = () => {
    setFeedbackModal(false);
  };

  return (
    <Grid className={classes.root}>
      <Grid container spacing={5} className="profilePageContainer">
        <Grid item xs={12} sm={3}>
          <Card className="profileImageContainer">
            <CardContent>
                <img className="recruitProfileImage" src={fileId} />
            </CardContent>
            <CardActions className="recruitActions">
              <a target="_blank" href={recruitData.facebookLink}>
                <Button size="small" variant="contained" className="btnAction facebookLink">
                  Facebook
                </Button>
              </a>

              <Button onClick={() => setFeedbackModal(true)} size="small" variant="contained" className="btnAction facebookLink">
                Add feedback
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Grid className="profileDetailsRow">
                <Typography>
                  <b>Recruit name</b>
                </Typography>
                <Typography>
                  {recruitData.firstName} {recruitData.lastName}
                </Typography>
              </Grid>
              <hr className="separator" />

              <Grid className="profileDetailsRow">
                <Typography>
                  <b>Email</b>
                </Typography>
                <Typography>{recruitData.email}</Typography>
              </Grid>
              <hr className="separator" />

              <Grid className="profileDetailsRow">
                <Typography>
                  <b>Phone</b>
                </Typography>
                <Typography>{recruitData.phone}</Typography>
              </Grid>
              <hr className="separator" />

              <Grid className="profileDetailsRow">
                <Typography>
                  <b>Token</b>
                </Typography>
                <Typography>{recruitData.token}</Typography>
              </Grid>
              <hr className="separator" />

              <Grid className="profileDetailsRow">
                <Typography>
                  <b>Faculty</b>
                </Typography>
                <Typography>{recruitData.faculty}</Typography>
              </Grid>
              <hr className="separator" />
              <Grid className="profileDetailsRow">
                <Typography>
                  <b>Year</b>
                </Typography>
                <Typography>{recruitData.year}</Typography>
              </Grid>
              <hr className="separator" />
                <Grid className="profileDetailsRow">
                    <Typography>
                        <b>Has checked final options:</b>
                    </Typography>
                    <Typography>{finalOptionsChecked ? "Yes" : "No"}</Typography>
                </Grid>
                <hr className="separator" />
              <Grid className="profileDetailsRow">
                <Typography>
                  <b>Option one</b>
                </Typography>
                <Typography>
                  {recruitData.optionOne}
                  {userData.isAdmin ?
                      <>
                      <IconButton disabled={decisionOne && decisionOne!=="ACCEPTAT"} className={classes.addButton} aria-label="acceptat" color="primary"
                                  onClick={() => {
                                    handleDecision(recruitData.id, 1, "ACCEPTAT")
                                    trackDecisionChange(recruitData.id,"","ACCEPTAT",1)
                                  }}>
                        <DoneIcon />
                      </IconButton>
                      <IconButton disabled={decisionOne && decisionOne!=="REFUZAT"} className={classes.deleteButton} aria-label="delete" color="primary"
                                  onClick={() => {
                                    handleDecision(recruitData.id, 1, "REFUZAT")
                                    trackDecisionChange(recruitData.id,"","REFUZAT",1)
                                  }}>
                        <HighlightOffIcon />
                      </IconButton>
                    <IconButton disabled={decisionOne && decisionOne!=="INDECIS"} className={classes.maybeButton} aria-label="delete" color="primary"
                    onClick={() => {
                      handleDecision(recruitData.id, 1, "INDECIS")
                      trackDecisionChange(recruitData.id,"","INDECIS",1)
                    }}>
                    <HelpOutlineIcon />
                    </IconButton>
                      </>
                      :null
                  }
                </Typography>
              </Grid>
              <hr className="separator" />
              <Grid className="profileDetailsRow">
                <Typography>
                  <b>Option two</b>
                </Typography>
                <Typography>
                  {recruitData.optionTwo}
                  {userData.isAdmin ?
                  <>
                    <IconButton disabled={decisionTwo && decisionTwo!=="ACCEPTAT"} className={classes.addButton} aria-label="delete" color="primary"
                                onClick={() => {
                                  handleDecision(recruitData.id, 2, "ACCEPTAT")
                                  trackDecisionChange(recruitData.id,"","ACCEPTAT",2)
                                }}>
                      <DoneIcon />
                    </IconButton>
                    <IconButton disabled={decisionTwo && decisionTwo!=="REFUZAT"} className={classes.deleteButton} aria-label="delete" color="primary"
                                onClick={() => {
                                  handleDecision(recruitData.id, 2, "REFUZAT")
                                  trackDecisionChange(recruitData.id,"","REFUZAT",2)
                                }}>

                      <HighlightOffIcon />
                    </IconButton>
                    <IconButton disabled={decisionTwo && decisionTwo!=="INDECIS"} className={classes.maybeButton} aria-label="delete" color="primary"
                                onClick={() => {
                                  handleDecision(recruitData.id, 2, "INDECIS")
                                  trackDecisionChange(recruitData.id,"","INDECIS",2)
                                }}>

                      <HelpOutlineIcon />
                    </IconButton>
                  </>
                      :null
                  }
                </Typography>
              </Grid>
              <hr className="separator" />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid>
        <Grid container justify={"center"} direction={"column"} alignItems={"center"} className="feedbackContainer" xs={12}>
          {userData.isAdmin || userData.isModerator ?
           <>
                <Typography>
                  <h1>Feedback</h1>
                </Typography>

                {Object.keys(feedbackData).map((phase) => {
                  return (
                    <Grid item xs={12} sm={6} justify={"center"} direction={"column"}>
                      <br></br>
                      <h2>
                        <h>
                          {phase === "interviewPhase"
                            ? "Interview Phase"
                            : phase === "acFirstPhase"
                            ? "First AC Phase"
                            : phase === "acSecondPhase"
                            ? "Second AC Phase"
                            : phase === "teamPhase"
                            ? "Team Project Phase"
                            : phase === "mentions"
                            ? "Mentions"
                            :""}
                        </h>
                      </h2>
                      {feedbackData[phase].map((feedback) => {
                        return (
                              <Paper key={feedback.id} className="feedbackPaper">
                            <span>
                              Given by:
                              <b>
                                {feedback.user?.firstName} {feedback.user?.lastName}
                              </b>
                            </span>
                                <br></br>
                                {feedback.detail !== null
                                    ? Object.keys(feedback.detail).map((detailName) => {
                                      if (
                                          detailName !== "id" &&
                                          detailName !== "createdAt" &&
                                          detailName !== "updatedAt" &&
                                          detailName !== "feedbackId"
                                      ) {
                                        return (
                                            <div className="standardFeedbackRatingContainer">
                                              <span>{convertDetailName(detailName)}</span>
                                              <Rating name={detailName} readOnly value={feedback.detail[detailName]} precision={1} />
                                            </div>
                                        );
                                      }
                                    })
                                    : null}
                                <br></br>
                                {feedback.text}
                                <br></br>
                              </Paper>

                        );
                      })}
                    </Grid>
                  );
                })},
          </>
          : null }
        </Grid>
      </Grid>
      <AddFeedback isOpened={feedbackModal} handleClose={handleModalClose} id={id}></AddFeedback>
    </Grid>
  );
}

export default RecruitProfile;
