
import './Login.css';
import {BACKEND_URL} from "../../config";

const Login = () => {
    const url=`${BACKEND_URL}/api/google`
  return (
      <div className="login">
        <div className="login-box">
          <h1>Banzai</h1>
          <a href={url}>
            <button type="button" className="login-with-google-btn">
              Sign in with Google
            </button>
          </a>
        </div>
      </div>
  )
}

export default Login;