import "./Teams.css";
import React, { useEffect, useState, useRef } from "react";
import TeamsCard from "../teamsCard/TeamsCard";
import { Link } from "react-router-dom";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Button from "@material-ui/core/Button";
import axios from "axios";
import SetNr from "./SetNr";
import Card from "@material-ui/core/Card";
import { IconButton } from "@material-ui/core";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import SelectSearch from "react-select-search";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {BACKEND_URL} from "../../config";

function Teams() {
    const [nr, setNr] = useState();
    const [teamModal, setTeamModal] = useState(false);
    const [teams, setTeams] = useState([]);
    const [selectVisible, setSelectVisible] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [allRecruits, setAllRecruits] = useState([]);
    const [changeRecruit, setChangeRecruit] = useState({});

    const searchInput = useRef();

    useEffect(() => {
        axios
            .get(BACKEND_URL +"/api/team/nr", { withCredentials: true })
            .then(res => {
                setNr(res.data.nr);

                axios
                    .get(
                        BACKEND_URL +"/api/recruit/teams/number/" + nr,
                        { withCredentials: true }
                    )
                    .then(res => {
                        setTeams(res.data);
                    })
                    .catch(err => console.log(err));
            });
    }, [nr, teamModal, dialogOpen]);

    useEffect(() => {
        axios
            .get(BACKEND_URL +"/api/recruit", { withCredentials: true })
            .then(res => {
                res.data.forEach(recruit => {
                    recruit.value = `${recruit.firstName} ${recruit.lastName}`;
                    recruit.name = `${recruit.firstName} ${recruit.lastName}`;
                });
                setAllRecruits(res.data);
            })
            .catch(err => console.log(err));
    }, [teamModal]);

    const handleModal = e => {
        e.preventDefault();
        setTeamModal(true);
    };

    const handleModalClose = () => {
        setTeamModal(false);
    };

    const toggleSelectVisibility = () => {
        setSelectVisible(prevSelectVisible => !prevSelectVisible);
    };

    const handleChange = (option, fullOption) => {
        setDialogOpen(true);
        // console.log(option);
        // console.log(fullOption);
        console.log(changeRecruit);
    };

    const handleFilter = items => {
        // console.log(items);

        return searchValue => {
            if (searchValue.length === 0) {
                return items;
            }

            const updatedItems = items.filter(item => {
                return item.name
                    .toLowerCase()
                    .includes(searchValue.toLowerCase());
            });

            return updatedItems;
        };
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleYes = () => {
        axios
            .put(
                `${BACKEND_URL}/api/recruit/teams/update?id=${changeRecruit.id}&nr=${changeRecruit.nextTeam}`,
                { withCredentials: true }
            )
            .then(res => {
                handleDialogClose();
            })
            .catch(err => console.log(err));
    };

    return (
        <div id="container">
            <Button
                className="btnAdd"
                variant="contained"
                style={{
                    margin: 20,
                    backgroundColor: "#0262DE",
                    color: "#fff"
                }}
                onClick={handleModal}
            >
                Generate teams
            </Button>
            <SetNr isOpened={teamModal} handleClose={handleModalClose} />

            <div className="container">
                {teams.length !== 0
                    ? Object.values(teams).map((team, index) => {
                          return team[0] ? (
                              <Card
                                  sx={{ minWidth: 275 }}
                                  className="float"
                                  id={team[0].teamNumber}
                              >
                                  <h3>Team {team[0].teamNumber}</h3>

                                  <IconButton
                                      color="primary"
                                      onClick={toggleSelectVisibility}
                                  >
                                      <GroupAddIcon style={{ color: "#fff" }} />
                                  </IconButton>
                                  {selectVisible && (
                                      <SelectSearch
                                          ref={searchInput}
                                          options={allRecruits}
                                          filterOptions={handleFilter}
                                          value=""
                                          name="RecruitDropdown"
                                          placeholder="Choose a recruit"
                                          search
                                          onChange={(option, fullOption) => {
                                              setChangeRecruit({
                                                  name: fullOption.name,
                                                  currentTeam:
                                                      fullOption.teamNumber,
                                                  nextTeam: team[0].teamNumber,
                                                  id: fullOption.id
                                              });
                                              handleChange(option, fullOption);
                                          }}
                                      />
                                  )}

                                  <Dialog
                                      open={dialogOpen}
                                      onClose={handleDialogClose}
                                      aria-labelledby="alert-dialog-title"
                                      aria-describedby="alert-dialog-description"
                                  >
                                      <DialogTitle id="alert-dialog-title">
                                          {
                                              "Are you sure you want to add the recruit to this team?"
                                          }
                                      </DialogTitle>
                                      <DialogContent>
                                          <DialogContentText id="alert-dialog-description">
                                              Do you want to move recruit{" "}
                                              {changeRecruit.name} from team{" "}
                                              {changeRecruit.currentTeam} to
                                              team {changeRecruit.nextTeam}?
                                          </DialogContentText>
                                      </DialogContent>
                                      <DialogActions>
                                          <Button onClick={handleDialogClose}>
                                              No
                                          </Button>
                                          <Button onClick={handleYes}>
                                              Yes
                                          </Button>
                                      </DialogActions>
                                  </Dialog>

                                  {Object.values(team).map(card => {
                                      return (
                                          <TeamsCard
                                              id={card.id}
                                              name={
                                                  card.firstName +
                                                  " " +
                                                  card.lastName
                                              }
                                              optionOne={card.optionOne}
                                              optionTwo={card.optionTwo}
                                          />
                                      );
                                  })}
                              </Card>
                          ) : // <div className="float" id={team[0].teamNumber}>
                          //     <p>Team {team[0].teamNumber}</p>
                          //     {Object.values(team).map(card => {
                          //         return (
                          //             <TeamsCard
                          //                 id={card.id}
                          //                 name={
                          //                     card.firstName +
                          //                     " " +
                          //                     card.lastName
                          //                 }
                          //                 optionOne={card.optionOne}
                          //                 optionTwo={card.optionTwo}
                          //             />
                          //         );
                          //     })}
                          // </div>
                          null;
                      })
                    : null}
            </div>
        </div>
    );
}

export default Teams;
