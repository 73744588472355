import React from "react";
import "../teams/Teams.css";

function TeamsCard(props) {
    const { id, name, optionOne, optionTwo } = props;

    return (
        <div id={id} className="card">
            <div className="card-header">
                <h3>{name}</h3>
            </div>
            <div className="card-body">
                <p>
                    <span>1.{optionOne}</span> <span>2.{optionTwo}</span>
                </p>
            </div>
        </div>
    );
}

export default TeamsCard;
