import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import "./TrackingList.css";
import axios from "axios";
import {BACKEND_URL} from "../../config";

function TrackingList() {
    const [data, setData] = useState([]);


    useEffect(async () => {
        try {
            await axios
                .get(BACKEND_URL +"/api/history/all", { withCredentials: true })
                .then((res) => {
                    setData(res.data.rows);
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        } finally {
        }
    }, []);

    function parseObject(obj) {
        let myArray = [];

        for (const [key, value] of Object.entries(obj)) {
            myArray.push(` ${key}: ${value} // `);
        }

        return myArray;
    }

    // useEffect(() => {
    //     newData.forEach(obj => {
    //         if (obj.event === "EDIT") {
    //             obj.from = parseObject(obj.from);
    //             obj.to = parseObject(obj.to);
    //         }
    //
    //         setData(prevData => [...prevData, obj]);
    //     });
    // }, []);

    return (
        <div className="tracking-list">
            <h2>
                Tracking
            </h2>
            <div className="table">
                <MaterialTable
                    options={{
                        maxBodyHeight: "800px", // with this you have a fixed header row
                        search: true,
                        //tableLayout: "fixed",
                        filtering: true,
                        grouping: false,
                        defaultExpanded: true,
                        paginationPosition: "both",
                        pageSizeOptions: [10, 20, 50, 100],
                        pageSize: 20,
                        emptyRowsWhenPaging: false,
                        headerStyle: {
                            backgroundColor: "#ecf0f1",
                            fontWeight: "bold"
                        }
                    }}
                    title={"Tracking List"}
                    columns={[
                        {
                            title: "Date",
                            field: "date",
                        },
                        {
                            title: "User",
                            field: "user"
                        },
                        {
                            title: "Target",
                            field: "target"
                        },
                        {
                            title: "Event",
                            field: "event"
                        },
                        {
                            title: "From",
                            field: "from"
                        },
                        {
                            title: "To",
                            field: "to"
                        }
                    ]}
                    data={data}
                />
            </div>
        </div>
    );
}

export default TrackingList;
