import { FormControl, Grid, InputLabel, Select, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import axios from 'axios'
import { toast } from 'react-toastify';
import { Link, useParams, useHistory } from 'react-router-dom'
import {useTracking} from "react-tracking";
import {BACKEND_URL} from "../../config";



const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '30%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        margin: '20px auto',
        [theme.breakpoints.down("sm")]: {
            width:"90%"
        },
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),

    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function AddRecruit(props) {
    let { id } = useParams();
    let history = useHistory();
    toast.configure();

    const { trackEvent }=useTracking( {page:id>0?"EditRecruit":"AddRecruit"});

    const classes = useStyles();
    var [file, setFile] = useState();
    const [recruitData, setRecruitData] = useState({
        lastName: '',
        firstName: '',
        token: '',
        email: '',
        phone: '',
        facebookLink: '',
        faculty: 'CSIE',
        year: '',
        optionOne: '',
        optionTwo: '',
    })
    const [editedData, setEditedData] =useState({});
    const [oldData, setOldData]=useState({});

    useEffect(async() => {
        if(id > 0){
            axios.get(BACKEND_URL +"/api/recruit/" + id, { withCredentials: true })
            .then((res) => {
                setRecruitData(res.data);
            })
        }
    }, [id]);
    const handleInputChange = (e) => {
        setRecruitData({ ...recruitData, [e.target.name]: e.target.value });
        setOldData({...oldData,[e.target.name]:recruitData[e.target.name]});
        setEditedData({...editedData, [e.target.name]:e.target.value});
    }
    const handleFileChange = async (e) => {
        setFile(e.target.files[0]);
    }


    const trackAddRecruit = (recruitId,fullName) => {
        trackEvent({
            event:"ADD_RECRUIT",
            target:recruitId,
            to:`${fullName} (ID:${recruitId})`
        })
    }

    const handleRecruitData = () => {
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }
        if(!file){
            toast.error(`No photo added.`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
        } else {
        
        axios.post(BACKEND_URL +'/api/recruit/add', recruitData, { withCredentials: true })
            .then((res) => {
                toast.success(`User added successfully`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
                const id = res.data.id;
                const fullName=res.data.firstName+" "+res.data.lastName;

                trackAddRecruit(id,fullName);

                const formData = new FormData();
                formData.append('image', file, file.name)
                axios.put(BACKEND_URL +"/api/recruit/image/" + id, formData, { withCredentials: true })
                    .then(res => {
                        console.log(res);
                    });
                history.push("/feedback");

            })
            .catch((err) => {
                if(err.response){
                   
                    console.log('Error: '+ err.response.statusText);
                toast.error(`${err.response.statusText}`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
                }
                
            })
        }
    }
    const handleEditData = () => {
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }
        
        axios.put(BACKEND_URL +'/api/recruit/update/' + id, recruitData, { withCredentials: true })
            .then((res) => {
                toast.success(`User updated successfully`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
                const id = res.data.id;
                console.log(id);
                
                if(file){

                const formData = new FormData();
                formData.append('image', file, file.name)
                axios.put(BACKEND_URL +"/api/recruit/image/" + id, formData, { withCredentials: true })
                    .then(res => {
                        console.log(res);
                    });
                }
                    history.push("/feedback");

            })
            .catch((err) => {
                if(err.response){
                console.log('Error:', err);
                toast.error(`${err.response.statusText}`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
            }
            })
    }


    const trackEditRecruit = () => {
        trackEvent({
            event:"EDIT_RECRUIT",
            target:id,
            from:oldData,
            to:editedData
        })
    }
    return (
        <div>

            <div className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant={"h4"}>{id>0?"Edit recruit":"Add recruit"}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="firstName"
                            label="First name"
                            name="firstName"
                            value={recruitData.firstName}
                            onChange={handleInputChange}
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="lastName"
                            label="Last name"
                            name="lastName"
                            value={recruitData.lastName}
                            onChange={handleInputChange}

                        />
                    </Grid>
                    <Grid item container xs={12} justify={"flex-start"}>
                        <input
                            accept="image/*"
                            className={classes.input}
                            style={{ display: 'none' }}
                            id="raised-button-file"
                            // multiple
                            type="file"
                            onChange={handleFileChange}
                        />
                        <label htmlFor="raised-button-file">
                            <Button variant="raised" component="span" className={classes.button}>
                                Profile picture
                        </Button>
                        </label>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            value={recruitData.email}
                            onChange={handleInputChange}

                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="phone"
                            label="Phone"
                            name="phone"
                            value={recruitData.phone}
                            onChange={handleInputChange}

                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="facebookLink"
                            label="Facebook link"
                            name="facebookLink"
                            value={recruitData.facebookLink}
                            onChange={handleInputChange}

                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth variant={"outlined"}>
                            <InputLabel htmlFor="age-native-simple">Faculty</InputLabel>
                            <Select
                                native
                                value={recruitData.faculty}
                                onChange={handleInputChange}
                                inputProps={{
                                    name: 'faculty',
                                    id: 'faculty',
                                }}
                            >   
                                <option value={'CSIE'}>CSIE</option>
                                <option value={'Universitate'}>Universitate</option>
                                <option value={'Politehnica'}>Politehnica</option>
                                <option value={'Other'}>Other</option>

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth variant={"outlined"}>
                            <InputLabel htmlFor="age-native-simple">Year</InputLabel>
                            <Select
                                native
                                value={recruitData.year}
                                onChange={handleInputChange}
                                inputProps={{
                                    name: 'year',
                                    id: 'year',
                                }}
                            >
                                <option aria-label="None" value="" />
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth variant={"outlined"}>
                            <InputLabel htmlFor="age-native-simple">Option One</InputLabel>
                            <Select
                                native
                                value={recruitData.optionOne}
                                onChange={handleInputChange}
                                inputProps={{
                                    name: 'optionOne',
                                    id: 'optionOne',
                                }}
                            >
                                <option aria-label="None" value="" />
                                <option value={'EDU'}>EDU</option>
                                <option value={'FR'}>FR</option>
                                <option value={'HR'}>HR</option>
                                <option value={'IP'}>I&P</option>
                                <option value={'IT back-end'}>IT Back-end</option>
                                <option value={'IT front-end'}>IT Front-end</option>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth variant={"outlined"}>
                            <InputLabel htmlFor="age-native-simple">Option Two</InputLabel>
                            <Select
                                native
                                value={recruitData.optionTwo}
                                onChange={handleInputChange}
                                inputProps={{
                                    name: 'optionTwo',
                                    id: 'optionTwo',
                                }}
                            >
                                <option aria-label="None" value="" />
                                <option value={'EDU'}>EDU</option>
                                <option value={'FR'}>FR</option>
                                <option value={'HR'}>HR</option>
                                <option value={'IP'}>I&P</option>
                                <option value={'IT back-end'}>IT Back-end</option>
                                <option value={'IT front-end'}>IT Front-end</option>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        {/* <Link to="/feedback"> */}
                            {(id > 0)? <Button
                                variant="contained"
                                style={{ backgroundColor: "#0262DE", color: "#ffffff" }}
                                startIcon={<AddCircleIcon />}
                                onClick={() =>{
                                    handleEditData();
                                    trackEditRecruit();
                                }}
                            >Edit recruit</Button> : 
                            <Button
                                variant="contained"
                                style={{ backgroundColor: "#0262DE", color: "#ffffff" }}
                                startIcon={<AddCircleIcon />}
                                onClick={handleRecruitData}
                            >Add recruit</Button>
                            }
                            
                        {/* </Link> */}
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default AddRecruit
