import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import "./Phases.css";
import axios from "axios";
import Button from "@material-ui/core/Button";
import AddPhase from "./AddPhase";
import { Select, Checkbox } from "@material-ui/core";
import {BACKEND_URL} from "../../config";

function Phases() {
  const [phaseModal, setPhaseModal] = useState(false);
  const [data, setData] = useState([]);
  const [dummy, setDummy] = useState(0);

  useEffect(() => {
    axios
      .get(BACKEND_URL +"/api/phase/all", { withCredentials: true })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }, [phaseModal, dummy]);

  const handleModal = (e) => {
    e.preventDefault();
    setPhaseModal(true);
  };

  const handleModalClose = () => {
    setPhaseModal(false);
  };

  const handleEnableChange = (id) => {
    axios
      .put(BACKEND_URL +"/api/phase/changeStatus/" + id, {
        withCredentials: true,
      })
      .then((res) => {
        console.log(res);
        setDummy(dummy + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="phases">
      <Button
        className="btnAdd"
        variant="contained"
        style={{ margin: 20, backgroundColor: "#0262DE", color: "#ffffff" }}
        onClick={handleModal}
      >
        Add phase
      </Button>
      <AddPhase isOpened={phaseModal} handleClose={handleModalClose} />
      <div className="tablePhase">
        <MaterialTable
          options={{
            maxBodyHeight: "800px", // with this you have a fixed header row
            search: true,
            //tableLayout: "fixed",
            filtering: true,
            grouping: false,
            defaultExpanded: true,
            paginationPosition: "both",
            pageSizeOptions: [10, 20, 50, 100],
            pageSize: 20,
            emptyRowsWhenPaging: false,
            headerStyle: {
              backgroundColor: "#ecf0f1",
              fontWeight: "bold",
            },
          }}
          title={"Phases list"}
          columns={[
            {
              title: "Name",
              field: "name",
            },

            {
              title: "Enabled",
              render: (row) => {
                // console.log("row", row);
                return (
                  <Checkbox
                    color="primary"
                    checked={row.isEnabled}
                    onChange={() => {
                      handleEnableChange(row.id);
                      // trackEnableStatus(row.id, row.isEnabled);
                    }}
                  />
                );
              },
            },
          ]}
          data={data}
        />
      </div>
    </div>
  );
}

export default Phases;
