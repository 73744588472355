import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, CircularProgress, FormControlLabel, Grid, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { useState } from "react";
import Button from "@material-ui/core/Button";
import Modal from '@material-ui/core/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import {useTracking} from "react-tracking";
import {BACKEND_URL} from "../../config";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '30%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        [theme.breakpoints.down("sm")]: {
            width:"90%"
        },
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const AddUser = (props) => {
    toast.configure();
    const { isOpened, handleClose, info, isEditing} = props;
    const classes = useStyles();
    const [isModalOpened, setIsModalOpened] = useState(false);
    //const [isEditing, setIsEditing] = useState(false);
    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        isEnabled: false,
        isAdmin: false,
        isModerator: false
    })

    const { trackEvent }=useTracking( {page:"AddUser"});

    const handleModalOpen = () => {
        setIsModalOpened(true);

    };

    const handleModalClose = () => {
        handleClose();
    };

    const handleInputChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    }

    const handleCheckboxChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.checked });
    }

    const trackAddUser = (userId) => {
        trackEvent({
            event:"ADD_USER",
            target:userId
        })
    }

    const handleUserData = () => {
        axios.post(BACKEND_URL +'/api/user/add', userData, { withCredentials: true })
            .then((res) => {
                toast.success(`User added successfully`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });

                trackAddUser(res.data.id);

                handleClose();
            })
            .catch((err) => {
                console.log('Error:', err.response.statusText);
                toast.error(`${err.response.statusText}`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
            })
    }

    const handleEdit = () => {
        

    }

    const handleAddItem = () => {
        setUserData({

        })
       // setIsEditing(false);
        handleModalOpen();
    }

    // const handleEditItem = (row) => {
    //     setIsEditing(true);
    //     handleModalOpen();
    // }
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={isOpened}
            onClose={handleModalClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isOpened}>
                <div className={classes.paper}>
                    <div className="modal-header">Add user</div>
                    <form className={classes.form} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First name"
                                    name="firstName"
                                    value={isEditing ? info.firstName : userData.firstName}
                                    onChange={handleInputChange}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Last name"
                                    name="lastName"
                                    value={isEditing ? info.lastName : userData.lastName}
                                    onChange={handleInputChange}

                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    value={isEditing ? info.email : userData.email}
                                    onChange={handleInputChange}

                                />
                            </Grid>
                            <Grid item container xs={12} justify={"space-evenly"}>
                                <FormControlLabel
                                    checked={isEditing ? info.isAdmin : userData.isAdmin}
                                    onChange={handleCheckboxChange}
                                    name={"isAdmin"}
                                    control={<Checkbox color="primary" />}
                                    label="Admin"
                                    labelPlacement="start"
                                /><FormControlLabel
                                    checked={isEditing ? info.isModerator : userData.isModerator}
                                    onChange={handleCheckboxChange}
                                    name={"isModerator"}
                                    control={<Checkbox color="primary" />}
                                    label="Moderator"
                                    labelPlacement="start"
                                />
                            </Grid>
                            <Grid item xs={12} direction={"row"} justify={"center"} alignItems={"center"}>
                                {isEditing ? <Button
                                    fullWidth
                                    variant="contained"
                                    style={{ backgroundColor: "#0262DE", color: "#ffffff" }}
                                    className={classes.submit}
                                    onClick={handleEdit}
                                >
                                    Edit user
                                    </Button> :
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        style={{ backgroundColor: "#0262DE", color: "#ffffff" }}
                                        className={classes.submit}
                                        onClick={handleUserData}
                                    >
                                        Add user
                                    </Button>}

                            </Grid>


                        </Grid>

                    </form>
                </div>
            </Fade>
        </Modal>
    )
}

export default AddUser;