import "./NavBar.css";
import { myContext } from "../../Context";
import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import PersonIcon from "@material-ui/icons/Person";
import GroupIcon from "@material-ui/icons/Group";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import AssignmentIcon from "@material-ui/icons/Assignment";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreIcon from "@material-ui/icons/MoreVert";
import HistoryIcon from "@material-ui/icons/History";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";
import {BACKEND_URL} from "../../config";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  title: {
    display: "block",
  },
  inputRoot: {
    color: "inherit",
  },
  link: {
    textDecoration: "none",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const NavBar = () => {
  const logoutUrl=`${BACKEND_URL}/logout`;

  const classes = useStyles();

  const userObject = useContext(myContext);

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    localStorage.setItem("isLoggedIn", false);
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("isModerator");
  };

  const mobileMenuId = "primary-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={"primary-account-menu-mobile"}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {userObject.isAdmin ? (
        <>
          <MenuItem component={Link} to="/users" onClick={handleMobileMenuClose}>
            <IconButton color="inherit">
              <PersonIcon />
            </IconButton>
            <p>Users</p>
          </MenuItem>

          <MenuItem component={Link} to="/teams" onClick={handleMobileMenuClose}>
            <IconButton color="inherit">
              <GroupIcon />
            </IconButton>
            <p>Teams</p>
          </MenuItem>

          <MenuItem component={Link} to="/tracking" onClick={handleMobileMenuClose}>
            <IconButton color="inherit">
              <HistoryIcon />
            </IconButton>
            <p>Tracking</p>
          </MenuItem>
          <MenuItem component={Link} to="/decisions" onClick={handleMobileMenuClose}>
            <IconButton color="inherit">
              <HistoryIcon />
            </IconButton>
            <p>Decisions</p>
          </MenuItem>
          <MenuItem component={Link} to="/phases" onClick={handleMobileMenuClose}>
            <IconButton color="inherit">
              <CollectionsBookmarkIcon />
            </IconButton>
            <p>Phases</p>
          </MenuItem>
        </>
      ) : null}

      <MenuItem component={Link} to="/feedback">
        <IconButton color="inherit">
          <AssignmentIcon />
        </IconButton>
        <p>Feedback</p>
      </MenuItem>
      <a href={logoutUrl}>
        <MenuItem onClick={handleLogout}>
          <IconButton color="inherit">
            <MeetingRoomIcon />
          </IconButton>
          <p>Logout</p>
        </MenuItem>
      </a>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar>
          <MenuItem component={Link} to="/homepage">
            <Typography className={classes.title} variant="h6" noWrap>
              Banzai
            </Typography>
          </MenuItem>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {userObject.isAdmin ? (
              <>
                <MenuItem component={Link} to="/users">
                  <IconButton color="inherit">
                    <PersonIcon />
                  </IconButton>
                  <p>Users</p>
                </MenuItem>

                <MenuItem component={Link} to="/teams">
                  <IconButton color="inherit">
                    <GroupIcon />
                  </IconButton>
                  <p>Teams</p>
                </MenuItem>

                <MenuItem component={Link} to="/tracking">
                  <IconButton color="inherit">
                    <HistoryIcon />
                  </IconButton>
                  <p>Tracking</p>
                </MenuItem>
                <MenuItem component={Link} to="/decisions">
                  <IconButton color="inherit">
                    <HistoryIcon />
                  </IconButton>
                  <p>Decisions</p>
                </MenuItem>
                <MenuItem component={Link} to="/phases">
                  <IconButton color="inherit">
                    <CollectionsBookmarkIcon />
                  </IconButton>
                  <p>Phases</p>
                </MenuItem>
              </>
            ) : null}

            <MenuItem component={Link} to="/feedback">
              <IconButton color="inherit">
                <AssignmentIcon />
              </IconButton>
              <p>Feedback</p>
            </MenuItem>

            <a href={logoutUrl}>
              <MenuItem onClick={handleLogout}>
                <IconButton color="inherit">
                  <MeetingRoomIcon />
                </IconButton>
                <p>Logout</p>
              </MenuItem>
            </a>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
  );
};

export default NavBar;
