import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import RecruitList from "./components/recruitList/RecruitList";
import reportWebVitals from "./reportWebVitals";
import Context from "./Context";
import track from "react-tracking";
import axios from "axios";
import {BACKEND_URL} from "./config";

const userId = localStorage.getItem("userId");

const WrapperApp = () => (
  <React.StrictMode>
    <Context>
      <App>
        <RecruitList></RecruitList>
      </App>
    </Context>
  </React.StrictMode>
);

const TrackedApp = track(
  { app: "banzai", userId },
  {
    // custom dispatch to console.log in addition to pushing to dataLayer[]
    dispatch: (data) => {
      console.log(data);
      // (window.dataLayer = window.dataLayer || []).push(data);
      try {
        axios
          .post(BACKEND_URL +"/api/history/add", data, {
            withCredentials: true,
          })
          .then((res) => {
            console.log(res.data);
          });
      } catch (err) {
        console.log(err);
      }
    },
  }
)(WrapperApp);

const rootElement = document.getElementById("root");
ReactDOM.render(<TrackedApp />, rootElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
