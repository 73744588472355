import React, { createContext, useEffect, useState } from 'react'
import axios from 'axios';
import { AxiosResponse } from 'axios';
import {BACKEND_URL} from "./config";

export const myContext = createContext({});
export default function Context(props) {

    const [userData, setUserData] = useState({
        isLoggedIn: false,
        firstName: "",
        userId: -1,
        isAdmin: false,
        isModerator: false
      });
      
  
      const checkLoggedIn = async () => {
          const isLoggedIn = localStorage.getItem("isLoggedIn") == "true";
          if (isLoggedIn) {
            let firstName = localStorage.getItem("firstName");
            let userId = localStorage.getItem("userId");
            let isAdmin = localStorage.getItem("isAdmin") == "true";
            let isModerator = localStorage.getItem("isModerator") == "true";
            setUserData({
              isLoggedIn: true,
              firstName: firstName,
              userId: userId,
              isAdmin: isAdmin,
              isModerator: isModerator
            });
          } else {
            axios.get(BACKEND_URL +"/api/getuser", { withCredentials: true }).then((res) => {
              if (res.data) {
                setUserData({
                  isLoggedIn: true,
                  firstName: res.data.firstName,
                  userId: res.data.id,
                  isAdmin: res.data.isAdmin,
                  isModerator: res.data.isModerator
                });
                  localStorage.setItem("isLoggedIn", true);
                  localStorage.setItem("firstName", res.data.firstName);
                  localStorage.setItem("userId", res.data.id);
                  localStorage.setItem("isAdmin", res.data.isAdmin);
                  localStorage.setItem("isModerator", res.data.isModerator);
              }
          })
          }
        };
  
        useEffect(() => {
          console.log('useEffect called');
          checkLoggedIn();
        }, []);
  

    return (
        <myContext.Provider value={userData}>{props.children}</myContext.Provider>
    )
}

