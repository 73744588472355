import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import axios from "axios";
import { useEffect } from "react";
import "./RecruitList.css";
import * as PropTypes from "prop-types";
import {
  Grid,
  Dialog,
  Typography,
} from "@material-ui/core";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {useTracking} from "react-tracking";
import {BACKEND_URL} from "../../config";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid container xs={12} justify={"center"}>
          {children}
        </Grid>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const RecruitList = (props) => {
  const [data, setData] = useState([]);
  const [aux, setAux] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);
  const [idDelete, setIdDelete] = useState();
  const [nameDelete, setNameDelete] = useState();
  let userObject = {};
  userObject.isAdmin = localStorage.getItem("isAdmin");
  userObject.isModerator = localStorage.getItem("isModerator");
  let history = useHistory();
  toast.configure();

  const { trackEvent }=useTracking( {page:'RecruitList'} );



  const handleDelete = () => {
    axios
      .delete(BACKEND_URL +"/api/recruit/delete/" + idDelete, {
        withCredentials: true,
      })
      .then((res) => {
        setOpenDialog(false);
        toast.success(`User deleted successfully`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        props.handleAux(aux + 1);
        history.push("/feedback");
      })
      .catch((err) => {
        if (err.response) {
          console.log("Error: " + err.response.statusText);
          toast.error(`${err.response.statusText}`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
        }
      });
  };

  const trackDeleteRecruit = () => {
    trackEvent({
      event:"DELETE_RECRUIT",
      target:nameDelete,
      to:`${nameDelete} (ID:${idDelete})`
    })
  }

  useEffect(async () => {
    try {
      await axios
        .get(BACKEND_URL +"/api/recruit", { withCredentials: true })
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    } finally {
    }
  }, [aux, openDialog]);


  const addClick = (e) => {
    window.open("/formrecruit/0", "_self");
  };
  return (
    <div className="table">
      {/*<form action="" class="search-bar" onSubmit={e => { e.preventDefault(); document.getElementById('search').blur() }}>*/}
      {/*    <input id="search" type="search" name="search" pattern=".*\S.*" required onChange={e => search(e.target.value)} />*/}
      {/*    <button class="search-btn" type="submit">*/}
      {/*        <span>Search</span>*/}
      {/*    </button>*/}
      {/*</form>*/}
      {userObject.isAdmin == "true" || userObject.isModerator == "true" ? (
        <Button
          variant="contained"
          style={{
            backgroundColor: "#0262DE",
            color: "#ffffff",
            margin: "10px",
          }}
          startIcon={<AddCircleIcon />}
          onClick={addClick}
        >
          Add recruit
        </Button>
      ) : null}

      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css"
        integrity="sha512-HK5fgLBL+xu6dm/Ii3z4xhlSUyZgTT9tuc/hSrtw6uzJOvgRr2a9jyxxT1ely+B+xFAmJKVSTbpM/CuL7qxO8w=="
        crossorigin="anonymous"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap"
        rel="stylesheet"
      />

      <MaterialTable
        className="table"
        options={{
          maxBodyHeight: "800px", // with this you have a fixed header row
          search: true,
          //tableLayout: "fixed",
          //selection: true,
          grouping: true,
          filtering: true,
          defaultExpanded: true,
          paginationPosition: "both",
          pageSizeOptions: [10, 20, 50, 100],
          pageSize: 20,
          emptyRowsWhenPaging: false,
          headerStyle: {
            backgroundColor: "#ecf0f1",
            fontWeight: "bold",
          },
        }}
        title={"Recruits list"}
        columns={[
          {
            title: "Last name",
            field: "lastName",
          },
          {
            title: "First name",
            field: "firstName",
          },
          {
            title: "Email",
            field: "email",
          },
          {
            title: "Phone",
            field: "phone",
          },
          {
            title: "Option one",
            field: "optionOne",
          },
          {
            title: "Option two",
            field: "optionTwo",
          },
        ]}
        actions={
          userObject.isModerator == "true" || userObject.isAdmin == "true"
            ? [
                {
                  icon: "visibility",
                  tooltip: "View recruit",
                  position: "row",
                  onClick: (event, rowData) =>
                    window.open(`/profile/${rowData.id}`, "_self"),
                },
                {
                  icon: "edit",
                  tooltip: "Edit recruit",
                  disabled: !(userObject.isAdmin || userObject.isModerator),
                  hidden: !(userObject.isAdmin || userObject.isModerator),
                  position: "row",
                  onClick: (event, rowData) =>
                    window.open(`/formrecruit/${rowData.id}`, "_self"),
                },
                {
                  icon: "delete",
                  disabled: !(userObject.isAdmin || userObject.isModerator),
                  hidden: !(userObject.isAdmin || userObject.isModerator),
                  tooltip: "Delete recruit",
                  position: "row",
                  onClick: (event, rowData) => {
                    setOpenDialog(true);
                    setIdDelete(rowData.id);
                    setNameDelete(`${rowData.firstName} ${rowData.lastName}`)
                    //handleDelete(rowData.id)
                  },
                },
              ]
            : [
                {
                  icon: "visibility",
                  tooltip: "View recruit",
                  position: "row",
                  onClick: (event, rowData) =>
                    window.open(`/profile/${rowData.id}`, "_self"),
                },
              ]
        }
        data={data}
      />

      <Dialog open={openDialog}>
        <div className="dialogContent">
          <Typography>Are you sure you want to delete this recruit?</Typography>
          <div className="dialogButtons">
            <Button
              onClick={() => {
                setIdDelete(-1);
                setOpenDialog(false);
              }}
            >
              No
            </Button>
            <Button onClick={() => {
              handleDelete();
              trackDeleteRecruit();
            }}>
              Yes
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default RecruitList;