import Login from '../login/Login';
import Button from "@material-ui/core/Button";
import "./Failed.css"
import { Link } from "react-router-dom"

function Failed() {
    return (
        <div className="App">
      <div className="text">
        <h1>404</h1>
        <h2>ACCES NEPERMIS</h2>
      </div>

      <img src="https://i.postimg.cc/VkJThKg8/kisspng-little-cloud-rain-sadness-clip-art-depressed-5ac032ab6d9753-8703060215225453234489.png" />
      <Link to="/">
      <Button variant="contained" 
      className="btn"
      style={{ backgroundColor: "#0262DE", color: "#ffffff" }}>
        Login
      </Button>
      </Link>
    </div>
    );
}

export default Failed;