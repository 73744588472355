
import React, { useState, useEffect, useContext, updateState, useCallback} from 'react';
import axios from 'axios'
import { myContext } from './../../Context';

import Statistics from "./Statistics"
import {BACKEND_URL} from "../../config";



function Homepage() {
    const userObject = useContext(myContext)
    const [recruitsCount, setRecruitsCount] = useState();
    const [hourDetails, setHourDetails] = useState();
    const [departmentCount, setDepartmentCount] = useState();


    

    // useEffect(() => {
    //     axios.get(BACKEND_URL +"/api/getuser", { withCredentials: true }).then((res) => {

    //         if (res.data) {
    //             setUserObject(res.data);
    //         }
    //     });

    // }, [])
    useEffect(() => {
        axios.get(BACKEND_URL +"/api/recruit/count",{ withCredentials: true, }).then(res => {
            // console.log(res);
            setRecruitsCount(res.data.count);

        });

        axios.get(BACKEND_URL +"/api/recruit/statistics/hour",{ withCredentials: true, }).then(res => {
            // console.log(res);
            setHourDetails(res.data);
            console.log(hourDetails)
            //let root = document.querySelector(':root');
            
            
            
        });

        axios.get(BACKEND_URL +"/api/recruit/statistics/departments",{ withCredentials: true, }).then(res => {
            // console.log(res);
            setDepartmentCount(res.data);
           // console.log(hourDetails)
            //let root = document.querySelector(':root');
            
            
            
        });

    }, [])

    useEffect(() => {
        const numb = document.querySelectorAll(".number");

        for (let i = 0; i < numb.length; i++) {
            let counter = 0;
            let value = numb[i].textContent;
            setInterval(() => {
                if (counter == value) {
                    clearInterval();
                } else {
                    counter += 1;
                    numb[i].textContent = counter;
                }
            }, 4000 / value);
        }
    }, [])

    return (

        <div>

            <h1>Welcome {userObject.firstName}</h1>
            {(hourDetails && departmentCount)? <Statistics recruitsCount={recruitsCount} hourDetails={hourDetails} departmentCount={departmentCount}></Statistics> : null}

        </div>
    );
}

export default Homepage;