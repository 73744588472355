import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Typography, List, ListItem, ListItemText,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import TextField from "@material-ui/core/TextField";
import { useState } from "react";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./AddFeedback.css";
import {BACKEND_URL} from "../../config";

const feedbackLegend=[
 "1. A povestit sau menționat activități sau experiențe prin care a trecut ca elev? (Debate, voluntariat, activități extracurriculare) Dacă da, ce ți s-a părut relevant?",
 "2. Cât de implicat a fost în cadrul activităților desfășurate de-a lungul interviului?",
  "3. Cum a comunicat cu ceilalți în cadrul activităților?",
  "4. A luat inițiativă de-a lungul interviului? Dacă da, în ce context? (ex. a inițiat conversații, a oferit idei noi etc.)",
  "5. A fost în dezacord cu părerea echipei? Dacă da, cum și-a exprimat părerile? Care a fost atitudinea lui la ideile echipei?",
  "6. În ce context și cum a reacționat recrutul când a primit feedback?",
  "7. În ce context și în ce mod a oferit recrutul feedback?",
  "8. Ce calități ale recrutului ai remarcat de-a lungul interviului? Ne poți detalia? (Creativitate, empatie, organizare, perseverență, adaptabilitate etc.)",
  "9. A existat vreo situație în care s-a făcut remarcat în mod negativ? Dacă da, povestește.",
  "10. Părere despre candidat de-a lungul interviului. (introvert, extrovert, deschidere față de ce se întâmplă, limbaj, atitudine, respect, răbdare, atenție etc.)",
  "11. Nu te-am întrebat, dar vrei să ne spui… "
]

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    borderRadius: "20px",
    width: "60%",
    height: "80%",
    overflowY: "scroll",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down("sm")]: {
      width:"90%",
      maxHeight:"95vh",
      overflow:"scroll"

    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const AddFeedback = (props) => {
  toast.configure();
  const { isOpened, handleClose, id } = props;
  const classes = useStyles();
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(2);
  const [hover, setHover] = useState(-1);
  let [userObject, setUserObject] = useState({});
  const [phases, setPhases] = useState([]);
  const [currentPhase, setCurrentPhase] = useState("");

  useEffect(() => {
    axios
      .get(BACKEND_URL +"/api/phase/all", { withCredentials: true })
      .then((res) => {
        console.log("PHASE", res);
        setPhases(res.data);
      });
  }, []);

  const [feedbackData, setFeedbackData] = useState({
    type: "short",
    text: "",
    recruitId: id,
    userId: userObject.id,
  });
  const [detaildata, setDetailData] = useState({
    leadership: 2,
    offerFeedback: 2,
    receiveFeedback: 2,
    social: 2,
    friendly: 2,
    feedbackId: 0,
  });

  const handleFeedbackData = async () => {
    await axios
      .get(BACKEND_URL +"/api/getuser", { withCredentials: true })
      .then((res) => {
        feedbackData.userId = res.data.id;
      });

    feedbackData.phaseId = currentPhase;
    axios
      .post(BACKEND_URL +"/api/feedback/add", feedbackData, {
        withCredentials: true,
      })
      .then((res) => {
        if (feedbackData.type == "standard") {
          detaildata.feedbackId = res.data.id;
          axios.post(BACKEND_URL +"/api/detail/add", detaildata, {
            withCredentials: true,
          });
        }
        toast.success(`Feedback added successfully`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        handleClose();
      })
      .catch((err) => {
        console.log("Error:", err);
        toast.error(`${err}`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      });
  };

  const handleModalOpen = () => {
    setIsModalOpened(true);
  };

  const handleModalClose = () => {
    handleClose();
  };

  const handleInputChange = (e) => {
    setFeedbackData({ ...feedbackData, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    setFeedbackData({ ...feedbackData, [e.target.name]: e.target.checked });
  };
  const handleRatingChange = (e) => {
    setDetailData({ ...detaildata, [e.target.name]: e.target.value });
  };

  const handleUserData = () => {
    axios
      .post(BACKEND_URL +"/api/feedback/add", feedbackData, {
        withCredentials: true,
      })
      .then((res) => {
        toast.success(`Feedback added successfully`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        handleClose();
      })
      .catch((err) => {
        console.log("Error:", err);
        toast.error(`${err.message}`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      });
  };

  const handleEdit = () => {};

  const handleAddItem = () => {
    setFeedbackData({});
    setIsEditing(false);
    handleModalOpen();
  };

  const handleEditItem = (row) => {
    setIsEditing(true);
    handleModalOpen();
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpened}
      onClose={handleModalClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpened}>
        <div className={classes.paper}>
          <div
            className="modal-header"
            style={{ marginBottom: "20px", fontSize: "1.5rem" }}
          >
            Add feedback
          </div>
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="age-native-simple">Type</InputLabel>
                  <Select
                    native
                    label="Type"
                    value={feedbackData.type}
                    onChange={handleInputChange}
                    inputProps={{
                      name: "type",
                      id: "type",
                    }}
                  >
                    <option value="short">Short</option>
                    <option value="long">Long</option>
                    {/*<option value="standard">Standard</option>*/}
                  </Select>
                </FormControl>
              </Grid>
              {feedbackData.type == "standard" ? (
                <div>
                  <Grid
                    container
                    xs={12}
                    direction={"row"}
                    justify={"space-between"}
                  >
                    <Typography variant={"body1"}>Leadership</Typography>

                    <Rating
                      name="leadership"
                      value={detaildata.leadership}
                      precision={1}
                      onChange={handleRatingChange}
                      onChangeActive={(event, newHover) => {
                        setHover(newHover);
                      }}
                    />
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    direction={"row"}
                    justify={"space-between"}
                  >
                    <Typography variant={"body1"}>Offer Feedback</Typography>

                    <Rating
                      name="offerFeedback"
                      value={detaildata.offerFeedback}
                      precision={1}
                      onChange={handleRatingChange}
                      onChangeActive={(event, newHover) => {
                        setHover(newHover);
                      }}
                    />
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    direction={"row"}
                    justify={"space-between"}
                  >
                    <Typography variant={"body1"}>Receive Feedback</Typography>

                    <Rating
                      name="receiveFeedback"
                      value={detaildata.receiveFeedback}
                      precision={1}
                      onChange={handleRatingChange}
                      onChangeActive={(event, newHover) => {
                        setHover(newHover);
                      }}
                    />
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    direction={"row"}
                    justify={"space-between"}
                  >
                    <Typography variant={"body1"}>Social</Typography>

                    <Rating
                      name="social"
                      value={detaildata.social}
                      precision={1}
                      onChange={handleRatingChange}
                      onChangeActive={(event, newHover) => {
                        setHover(newHover);
                      }}
                    />
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    direction={"row"}
                    justify={"space-between"}
                  >
                    <Typography variant={"body1"}>Friendly</Typography>

                    <Rating
                      name="friendly"
                      value={detaildata.friendly}
                      precision={1}
                      onChange={handleRatingChange}
                      onChangeActive={(event, newHover) => {
                        setHover(newHover);
                      }}
                    />
                  </Grid>
                </div>
              ) : null}
              <Grid item xs={12} style={{ marginTop: "20px" }}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Etapa
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={currentPhase}
                    onChange={(e) => setCurrentPhase(e.target.value)}
                    label="Etapa"
                    style={{ textAlign: "left" }}
                  >
                    {Object.values(
                      phases.map((phase) => {
                        return phase.isEnabled ? (
                          <MenuItem value={phase.id}>{phase.name}</MenuItem>
                        ) : null;
                      })
                    )}
                  </Select>
                </FormControl>
              </Grid>

              {currentPhase === 1 ? (
                  <Grid item xs={12}>
                    {feedbackLegend.map(feedback => <p className={"hint-item"}>{feedback}</p>)}
                  </Grid>
              ):null}

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  multiline
                  rowsMax="8"
                  id="text"
                  label="Feedback"
                  name="text"
                  value={feedbackData.text}
                  onChange={handleInputChange}
                  autoFocus
                />
              </Grid>

              <Grid
                item
                xs={12}
                direction={"row"}
                justify={"center"}
                alignItems={"center"}
              >
                <Link to={`/profile/${id}`} className="link">
                  {isEditing ? (
                    <Button
                      fullWidth
                      variant="contained"
                      style={{
                        backgroundColor: "#0262DE",
                        color: "#ffffff",
                      }}
                      className={classes.submit}
                      onClick={handleEdit}
                    >
                      Edit user
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      variant="contained"
                      style={{
                        backgroundColor: "#0262DE",
                        color: "#ffffff",
                      }}
                      className={classes.submit}
                      onClick={handleFeedbackData}
                    >
                      Add feedback
                    </Button>
                  )}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Fade>
    </Modal>
  );
};

export default AddFeedback;
