import axios from "axios";
import { useEffect, useRef } from "react";
import React, { useState } from "react";
import "./UserList.css";
import AddUser from "../addUser/AddUser";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Button from "@material-ui/core/Button";
import MaterialTable from "material-table";
import { Select, Checkbox } from "@material-ui/core";
import CSVReader from "react-csv-reader";

import { useTracking } from "react-tracking";
import {BACKEND_URL} from "../../config";

const UsersList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [userModal, setUserModal] = useState(false);
  const [info, setInfo] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [dummy, setDummy] = useState(0);

  const { trackEvent } = useTracking({ page: "UserList" });

  useEffect(async () => {
    setLoading(true);
    try {
      await axios
        .get(BACKEND_URL +"/api/user/all", { withCredentials: true })
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.log(err);
          setError(err);
        });
    } catch (err) {
      console.log(err);
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [userModal, dummy]);

  const handleModal = (e, rowData, editing) => {
    e.preventDefault();
    setIsEditing(editing);
    setInfo(rowData);
    setUserModal(true);
  };

  const handleModalClose = () => {
    setUserModal(false);
  };
  const changeEnableStatus = async (id) => {
    try {
      await axios
        .get(BACKEND_URL +"/api/user/enabled/" + id, {
          withCredentials: true,
        })
        .then((res) => {
          setDummy(dummy + 1);
          //setEnabled(!enabled);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const trackEnableStatus = (id, value) => {
    trackEvent({
      event: "ENABLED_CHANGE",
      target: id,
      from: value,
      to: !value,
    });
  };

  const handleChange = (id, value) => {
    const detail = {
      id: id,
      status: value,
    };
    try {
      axios
        .put(BACKEND_URL +"/api/user/status", detail, {
          withCredentials: true,
        })
        .then((res) => {
          setDummy(dummy + 1);
          // console.log(res.data);
          // setModerator(!moderator);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const trackStatusChange = (id, value, targetValue) => {
    trackEvent({
      event: "STATUS_CHANGE",
      target: id,
      from: value,
      to: targetValue,
    });
  };

  return (
    <div className={"table"}>
      <Button
        variant="contained"
        style={{ backgroundColor: "#0262DE", color: "#ffffff", margin: "10px" }}
        startIcon={<AddCircleIcon />}
        onClick={(e) => handleModal(e, {}, false)}
      >
        Add user
      </Button>

      <CSVReader
        onFileLoaded={(data, fileInfo) => {
          axios(BACKEND_URL +"/api/user/importUsersFromCSV", {
            method: "post",
            data: {
              users: data,
            },
            withCredentials: true,
          })
            .then((res) => {
              console.log(res);
              setDummy(dummy + 1);
            })
            .catch((err) => {
              console.log(err);
            });
        }}
      />

      <AddUser
        isOpened={userModal}
        handleClose={handleModalClose}
        info={info}
        isEditing={isEditing}
      />

      <div>
        <MaterialTable
          options={{
            maxBodyHeight: "800px", // with this you have a fixed header row
            search: true,
            //tableLayout: "fixed",
            filtering: true,
            grouping: false,
            defaultExpanded: true,
            paginationPosition: "both",
            pageSizeOptions: [10, 20, 50, 100],
            pageSize: 20,
            emptyRowsWhenPaging: false,
            headerStyle: {
              backgroundColor: "#ecf0f1",
              fontWeight: "bold",
            },
          }}
          title={"Users list"}
          columns={[
            {
              title: "Last name",
              field: "lastName",
            },
            {
              title: "First name",
              field: "firstName",
            },
            {
              title: "Email",
              field: "email",
            },
            {
              title: "Status",
              render: (row) => {
                const role = row.isAdmin
                  ? "ADMIN"
                  : row.isModerator
                  ? "MODERATOR"
                  : "USER";
                return (
                  <Select
                    native
                    labelId="status"
                    id="status"
                    value={role}
                    label="Status"
                    onChange={(e) => {
                      handleChange(row.id, e.target.value);
                      trackStatusChange(row.id, role, e.target.value);
                    }}
                  >
                    <option value={"ADMIN"}>ADMIN</option>
                    <option value={"MODERATOR"}>MODERATOR</option>
                    <option value={"USER"}>USER</option>
                  </Select>
                );
              },
            },
            {
              title: "Enabled",
              render: (row) => {
                // console.log("row", row);
                return (
                  <Checkbox
                    color="primary"
                    checked={row.isEnabled}
                    onChange={() => {
                      changeEnableStatus(row.id);
                      trackEnableStatus(row.id, row.isEnabled);
                    }}
                  />
                );
              },
            },
          ]}
          data={data}
        />
      </div>
    </div>
  );
};

export default UsersList;
