import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import {
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    Typography
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { useState } from "react";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {BACKEND_URL} from "../../config";

const useStyles = makeStyles(theme => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        width: "30%",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        [theme.breakpoints.down("sm")]: {
            width:"90%"
        },
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

const SetNr = props => {
    toast.configure();
    const { isOpened, handleClose } = props;
    const classes = useStyles();
    const [isModalOpened, setIsModalOpened] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [teamData, setTeamData] = useState({
        nr: null
    });

    const handleModalOpen = () => {
        setIsModalOpened(true);
    };

    const handleModalClose = () => {
        handleClose();
    };

    const handleInputChange = e => {
        setTeamData({ ...teamData, [e.target.name]: e.target.value });
    };

    const handleSubmit = e => {
        e.preventDefault();
    };

    const handleTeamData = () => {
        axios
            .put(BACKEND_URL +"/api/team/changeNr/" + teamData.nr, {
                withCredentials: true
            })
            .then(res => {
                axios
                    .get(
                        BACKEND_URL +"/api/recruit/teams/" +
                            teamData.nr,
                        { withCredentials: true }
                    )
                    .then(res => {
                        toast.success(`Teams generated.`, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined
                        });
                        handleClose();
                    });
            })
            .catch(err => {
                console.log("Error:", err);
                toast.error(`${err.message}`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined
                });
            });
    };

    const handleAddItem = () => {
        setTeamData({});
        setIsEditing(false);
        handleModalOpen();
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={isOpened}
            onClose={handleModalClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
        >
            <Fade in={isOpened}>
                <div className={classes.paper}>
                    <div className="modal-header">Teams number</div>
                    <form
                        className={classes.form}
                        noValidate
                        onSubmit={handleSubmit}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="nr"
                                    label="Teams number"
                                    name="nr"
                                    value={teamData.nr}
                                    onChange={handleInputChange}
                                    autoFocus
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                direction={"row"}
                                justify={"center"}
                                alignItems={"center"}
                            >
                                <Button
                                    fullWidth
                                    variant="contained"
                                    style={{
                                        backgroundColor: "#0262DE",
                                        color: "#ffffff"
                                    }}
                                    className={classes.submit}
                                    onClick={handleTeamData}
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Fade>
        </Modal>
    );
};

export default SetNr;
