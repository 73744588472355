//TODO: Page not implemented yet
import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import "./DecisionsList.css";
import axios from "axios";
import {Select} from "@material-ui/core";
import {useTracking} from "react-tracking";
import {BACKEND_URL} from "../../config";

const DecisionsList = () =>{
    const [data, setData] = useState([]);
    const [dummy,setDummy]=useState(0);

    const { trackEvent } = useTracking({ page: "DecisionsList" });


    useEffect(async () => {
        try {
            await axios
                .get(BACKEND_URL +"/api/recruit/withDecision", { withCredentials: true })
                .then((res) => {
                    setData(res.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        } finally {
        }
    }, [dummy]);


    const handleChange = (recruitId,optionNo,decision) => {
        const castDecision=decision;
        if(decision==="NESELECTAT") decision=null;
        axios.post(BACKEND_URL +"/api/recruit/castDecision",{recruitId,optionNo,decision})
            .then(res=>{
                setDummy(dummy+1);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const trackDecisionChange = (id,from,to,decision) => {
        trackEvent({
            event:`CHANGE_DECISION_${decision}`,
            target:id,
            from:from===null?"NESELECTAT":from,
            to:to
        })

    }

    return (
        <div className="tracking-list">
            <h2>
                Decisions
            </h2>
            <div className="table">
                <MaterialTable
                    options={{
                        maxBodyHeight: "800px", // with this you have a fixed header row
                        search: true,
                        //tableLayout: "fixed",
                        filtering: true,
                        grouping: true,
                        defaultExpanded: true,
                        paginationPosition: "both",
                        pageSizeOptions: [10, 20, 50, 100],
                        pageSize: 20,
                        emptyRowsWhenPaging: false,
                        headerStyle: {
                            backgroundColor: "#ecf0f1",
                            fontWeight: "bold"
                        }
                    }}
                    title={"Decision List"}
                    columns={[
                        {
                            title: "First Name",
                            field: "firstName",
                        },
                        {
                            title: "Last Name",
                            field: "lastName",
                        },
                        {
                            title: "Option one",
                            field: "optionOne",
                        },
                        {
                            title: "Decision one",
                            render: (row) => {
                                return (
                                    <Select
                                        native
                                        labelId="status"
                                        id="status"
                                        value={(row.decisionOne===null)? "NESELECTAT" : row.decisionOne}
                                        label="Status"
                                        onChange={(e) => {
                                            handleChange(row.id,1,e.target.value);
                                            trackDecisionChange(row.id,row.decisionOne,e.target.value,1);

                                        }}
                                    >
                                        <option value={"NESELECTAT"}>NESELECTAT</option>
                                        <option value={"ACCEPTAT"}>ACCEPTAT</option>
                                        <option value={"REFUZAT"}>REFUZAT</option>
                                        <option value={"INDECIS"}>INDECIS</option>
                                    </Select>
                                );
                            },
                        },
                        {
                            title: "Option two",
                            field: "optionTwo",
                        },
                        {
                            title: "Decision two",
                            render: (row) => {
                                return (
                                    <Select
                                        native
                                        labelId="status"
                                        id="status"
                                        value={(row.decisionTwo===null)? "NESELECTAT" : row.decisionTwo}
                                        label="Status"
                                        onChange={(e) => {
                                            handleChange(row.id,2,e.target.value);
                                            trackDecisionChange(row.id,row.decisionTwo,e.target.value,2);
                                        }}
                                    >
                                        <option value={"NESELECTAT"}>NESELECTAT</option>
                                        <option value={"ACCEPTAT"}>ACCEPTAT</option>
                                        <option value={"REFUZAT"}>REFUZAT</option>
                                        <option value={"INDECIS"}>INDECIS</option>
                                    </Select>
                                );
                            },
                        },

                    ]}
                    data={data}
                />
            </div>
        </div>
    );
}

export default DecisionsList;
