import { useEffect } from "react";
import './HomePage.css'

function Statistics(props) {
    const { recruitsCount, hourDetails, departmentCount } = props;
    console.log(hourDetails);
    let width = Math.round(hourDetails.sixTwelve * 100 / recruitsCount);
    document.documentElement.style.setProperty('--one', `${width}%`);
    width = Math.round(hourDetails.thirteenFive * 100 / recruitsCount);
    document.documentElement.style.setProperty('--two', `${width}%`);
    width = Math.round(hourDetails.sixEleven * 100 / recruitsCount);
    document.documentElement.style.setProperty('--three', `${width}%`);
    width = Math.round(hourDetails.twelveFive * 100 / recruitsCount);
    document.documentElement.style.setProperty('--four', `${width}%`);
    return (
        <div className="statistics">

            <div className="firstCircle">
                
                <div class="circular">

                    <div class="inner"></div>

                    <div class="number">Registered {recruitsCount}</div>
                    <div class="circle">
                        <div class="bar left">
                            <div class="progress"></div>
                        </div>
                        <div class="bar right">
                            <div class="progress"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="circles">
                <div class="circular">
                    <div class="inner"></div>
                    <div class="number">EDU {departmentCount.edu}</div>
                    <div class="circle">
                        <div class="bar left">
                            <div class="progress"></div>
                        </div>
                        <div class="bar right">
                            <div class="progress"></div>
                        </div>
                    </div>
                </div>
                <div class="circular">
                    <div class="inner"></div>
                    <div class="number">FR {departmentCount.fr}</div>
                    <div class="circle">
                        <div class="bar left">
                            <div class="progress"></div>
                        </div>
                        <div class="bar right">
                            <div class="progress"></div>
                        </div>
                    </div>
                </div>
                <div class="circular">
                    <div class="inner"></div>
                    <div class="number">HR {departmentCount.hr}</div>
                    <div class="circle">
                        <div class="bar left">
                            <div class="progress"></div>
                        </div>
                        <div class="bar right">
                            <div class="progress"></div>
                        </div>
                    </div>
                </div>
                <div class="circular">
                    <div class="inner"></div>
                    <div class="number">I&P {departmentCount.ip}</div>
                    <div class="circle">
                        <div class="bar left">
                            <div class="progress"></div>
                        </div>
                        <div class="bar right">
                            <div class="progress"></div>
                        </div>
                    </div>
                </div>
                <div class="circular">
                    <div class="inner"></div>
                    <div class="number">IT Back-end {departmentCount.itBack}</div>
                    <div class="circle">
                        <div class="bar left">
                            <div class="progress"></div>
                        </div>
                        <div class="bar right">
                            <div class="progress"></div>
                        </div>
                    </div>
                </div>
                <div class="circular">
                    <div class="inner"></div>
                    <div class="number">IT Front-end {departmentCount.itFront}</div>
                    <div class="circle">
                        <div class="bar left">
                            <div class="progress"></div>
                        </div>
                        <div class="bar right">
                            <div class="progress"></div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="barChart">
                <article className="text">
                    <div className="textDiv">06:00 - 12:00</div>
                    <div className="textDiv">13:00 - 17:00</div>
                    <div className="textDiv">18:00 - 23:00</div>
                    <div className="textDiv">00:00 - 05:00</div>
                </article>
                <article id="bar_graph">

                    <div>
                        <p>{Math.round(hourDetails.sixTwelve * 100 / recruitsCount * 100) / 100}%</p>
                    </div>
                    <div>
                        <p>{Math.round(hourDetails.thirteenFive * 100 / recruitsCount * 100) / 100}%</p>
                    </div>
                    <div>
                        <p>{Math.round(hourDetails.sixEleven * 100 / recruitsCount * 100) / 100}%</p>
                    </div>
                    <div>
                        <p>{Math.round(hourDetails.twelveFive * 100 / recruitsCount * 100) / 100}%</p>
                    </div>
                </article>

            </section>
        </div>

    );
}

export default Statistics;